import { BottomSheet } from 'react-spring-bottom-sheet'
import styled, { css } from 'styled-components'

import { Button } from '@src/components/Button'
import { ResetLink } from '@src/components/MobileServiceOverlay/MobileServiceOverlayStyles'
import { breakpoints } from '@src/constants/breakpoints'

export const StyledBottomSheetBackdrop = styled.div<{ showBackdrop: boolean }>`
  z-index: 15;
  overscroll-behavior: none;
  touch-action: none;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  background: none;
  background-color: ${({ showBackdrop }) =>
    showBackdrop ? 'rgba(0, 0, 0, 0.6)' : 'rgba(0, 0, 0, 0.0)'};
  will-change: opacity;
  cursor: unset;
  height: 100vh;
  width: 100vw;
`

export const StyledBottomSheet = styled(BottomSheet)<{
  $headerColor?: string
  $maxWidth?: number
  $backgroundColor?: string
  $isMinimised?: boolean
}>`
  [data-rsbs-backdrop] {
    display: none;
  }
  [data-rsbs-overlay] {
    z-index: 16;
    max-width: ${({ $maxWidth }) => ($maxWidth ? `${$maxWidth}px` : '616px')};
    margin: auto;
    transition: height ease-in-out;
    ${({ $isMinimised }) =>
      $isMinimised &&
      `
          height: 1px !important;
        `}
  }
  [data-rsbs-header] {
    // TODO headerColor is only set by ServicePopover, so another SC could just extend
    // everywhere else (lots) passes in #fff
    /// which is funny cause its the default
    background-color: ${({ $headerColor, theme }) =>
      $headerColor === 'brandColor'
        ? theme.colors.brand
        : $headerColor || '##fff'};

    border-radius: 8px 8px 0px 0px;
    padding: 0;
    height: 40px;
  }
  [data-rsbs-header]::before {
    background-color: ${({ theme }) => theme.colors.brand};
    width: 32px;
    height: 4px;
    border-radius: 2px;
    margin-top: 4px;
  }
  [data-rsbs-content] {
    background-color: ${({ $backgroundColor }) => $backgroundColor || '#fff'};
  }
  [data-rsbs-scroll] {
    background-color: ${({ $backgroundColor }) => $backgroundColor || '#fff'};
  }
`

export const SectionHeaderContent = styled.div`
  h3 {
    margin: 0;
    font-size: 18px;
    font-weight: 700;
  }
  h4 {
    margin: 0;
    font-size: 14px;
    color: #939393;
    font-weight: 500;
  }
  margin-top: 4px;
  margin-bottom: 4px;
`

export const PopoverOptionContainer = styled.div`
  padding: 4px 0;
`

export const PopoverNoAddressContainer = styled.div`
  padding: 16px 8px;
`

export const OptionContentTitle = styled.span`
  display: block;
  margin: 0;
  font-size: 16px;
  font-weight: 700;
`

export const OptionContentText = styled.span`
  display: block;
  margin: 0;
  font-size: 16px;
  flex: 1;
  white-space: nowrap;
`

export const OptionContentSubTitle = styled.span<{ disabled?: boolean }>`
  display: block;
  margin: 0;
  font-size: 12px;
  color: #939393;
  font-weight: 500;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
    `}
`

export const OptionLabel = styled.div`
  width: 100%;
  display: flex;
  font-weight: 700;
  hover: {
    cursor: pointer;
  }
`

export const OptionContentLabels = styled.div`
  width: calc(100% - 75px);
  flex: 1;
`

export const Separator = styled.hr`
  width: 100%;
  color: #e5e5e5;
  border: none;
  border-top: 1px solid #ebecf0;
  margin: 8px 0;

  @media (min-width: ${breakpoints.tablet}px) {
    margin: 16px 0;
  }
`

export const MobileNavigationPopoverFooter = styled.div`
  align-self: flex-end;
  width: 100%;
  /* Additional styling for the fixed content */
  position: absolute;
  bottom: 0;
  background-color: #fff;
`

export const MobileNavigationPopoverContent = styled.div`
  padding: 0 16px;
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  @media (min-width: ${breakpoints.tablet}px) {
    padding: 0 32px;
  }
`

export const MobileNavigationPopoverFooterContent = styled.div`
  justify-content: space-between;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 584px;
  display: flex;
  padding: 16px 0;
`

export const MobileNavigationResetLink = styled(ResetLink)`
  width: 180px;
  padding-left: 16px;
`

export const MobileNavigationPopoverContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 100px;
`

export const DoneButton = styled(Button)`
  margin-right: 16px;
`

export const TimeFullSpan = styled.span`
  float: right;
`

export const SectionContentContainer = styled.div`
  padding: 6px 0;
`
export const SelectWithArrowSpan = styled.span`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
`
