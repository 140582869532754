import * as Types from '../../../../graphql-types'

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
export type getSingleLoyaltyCardQueryVariables = Types.Exact<{
  id: Types.Scalars['String']
}>

export type getSingleLoyaltyCardQuery = {
  singleLoyaltyCard: {
    __typename: 'LoyaltyCard'
    id: string
    requiredStamps: number
    termsAndConditions: string
    discountStrategy: Types.LoyaltyCardDiscountStrategy
    loyaltyCardColor: string
    allowCashOrders: boolean
    loyaltyCardIcon: string
    netTotal: number | null
    canStampBeEarnedToday: boolean | null
    active: boolean
    loyaltyCardStamps: Array<{
      __typename: 'LoyaltyCardStamp'
      id: string
      amountEarnedOnOrder: number
      stampClaimedOnOrder: { __typename: 'Order'; id: string } | null
      customer: { __typename: 'Customer'; id: string }
      order: {
        __typename: 'Order'
        id: string
        orderNumber: string
        netTotal: number
        discountAmount: number | null
      }
    }>
    discount: {
      __typename: 'Discount'
      id: string
      name: string
      discountAmount: number | null
      discountPercentage: number | null
      customerEmail: string | null
      customerEnrolled: boolean
      daysOfWeek: Array<number> | null
      startDate: string | null
      endDate: string | null
      minimumSubtotalGross: number | null
      enrolmentKey: string | null
      key: string | null
      voucher: string | null
      loyaltyCard: {
        __typename: 'LoyaltyCard'
        id: string
        discountStrategy: Types.LoyaltyCardDiscountStrategy
      } | null
      restaurant: { __typename: 'Restaurant'; id: string } | null
      allowedOutlets: Array<{
        __typename: 'Outlet'
        id: string
        displayName: string
      }>
    }
  }
}

export const getSingleLoyaltyCardDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getSingleLoyaltyCard' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'singleLoyaltyCard' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'requiredStamps' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'termsAndConditions' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'discountStrategy' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'loyaltyCardColor' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'allowCashOrders' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'loyaltyCardIcon' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'netTotal' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'canStampBeEarnedToday' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'loyaltyCardStamps' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'amountEarnedOnOrder' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'stampClaimedOnOrder' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'customer' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'order' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'orderNumber' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'netTotal' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'discountAmount' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'discount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'discountAmount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'discountPercentage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'customerEmail' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'customerEnrolled' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'daysOfWeek' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'startDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'minimumSubtotalGross' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'enrolmentKey' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'loyaltyCard' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'discountStrategy' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'restaurant' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'voucher' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'allowedOutlets' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'displayName' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  getSingleLoyaltyCardQuery,
  getSingleLoyaltyCardQueryVariables
>
