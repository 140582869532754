import { format, formatDistanceToNow } from 'date-fns'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { NarrowFulfilmentMethodInputType } from '@src/graphql-types'
import { getFormattedDate } from '@src/utils/getFormattedDate'
import { imageJitURL } from '@src/utils/imageJitURL'

import { OrderProgress } from './OrderProgress'
import {
  RestaurantLogo,
  StatusBoxContainer,
  StatusBoxPlaced,
  StatusBoxText,
  StatusBoxTextHeader,
  StatusBoxUpdate,
  StatusInfoContainer,
  StatusPhotoText,
} from './StatusBox.styles'

export const ReadyStatusBox: React.VFC<{
  updatedAt: string
  restaurantName: string
  restaurantLogo: string | null
  fulfillmentMethod: NarrowFulfilmentMethodInputType
  endWindow: Date
  isAccountPage: boolean
  startWindow?: Date
  formattedDeliveryDate?: Date
  formattedCollectionDate?: Date
}> = ({
  updatedAt,
  startWindow,
  restaurantLogo,
  restaurantName,
  endWindow = new Date(), // TODO: should be required when live, default date for now cause of missing data
  fulfillmentMethod,
  formattedDeliveryDate,
  formattedCollectionDate,
  isAccountPage,
}) => {
  const orderUpdatedAt = formatDistanceToNow(new Date(updatedAt), {
    addSuffix: true,
  })
  const { t } = useTranslation('singleOrder')

  return (
    <StatusBoxContainer isAccountPage={isAccountPage}>
      <div>
        <StatusBoxPlaced>
          {format(new Date(updatedAt), 'dd MMM yyyy')}
        </StatusBoxPlaced>

        <StatusBoxUpdate>
          {t('updated')} {orderUpdatedAt}
        </StatusBoxUpdate>
      </div>
      <StatusPhotoText>
        <RestaurantLogo
          role="img"
          aria-label={`${restaurantName} logo`}
          imageUrl={imageJitURL(restaurantLogo, {
            resize: {
              width: 128,
              height: 128,
              fit: 'cover',
            },
            toFormat: 'jpeg',
          })}
        />
        <StatusInfoContainer>
          <StatusBoxTextHeader>{restaurantName}</StatusBoxTextHeader>
          <StatusBoxText>
            {fulfillmentMethod === 'COLLECTION' ? (
              t('ready_for_collection')
            ) : (
              <>
                {t('order_on_way')}
                <br />
                <strong>
                  {getFormattedDate(
                    fulfillmentMethod,
                    endWindow,
                    startWindow,
                    formattedDeliveryDate,
                    formattedCollectionDate
                  )}
                </strong>
              </>
            )}
            <br />
          </StatusBoxText>
        </StatusInfoContainer>
      </StatusPhotoText>
      <OrderProgress valueNow={3} />
    </StatusBoxContainer>
  )
}
