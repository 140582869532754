import { useQuery } from '@apollo/client'
import { truncate } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { AllergenTags } from '@src/components/AllergenTags/AllergenTags'
import { ItemCounter } from '@src/components/ItemCounter/ItemCounter'
import { SpicyChiliSVG } from '@src/components/SVGS/SpicyChiliSVG'
import { useBasketItems } from '@src/hooks/useBasketItems/useBasketItems'
import { useMenuItemQueryParam } from '@src/hooks/useMenuItemQueryParam'
import { MAXIMUM_DESCRIPTION_LENGTH } from '@src/pages/OutletPage/OutletMenu/MenuItem/MAX_DESCRIPTION_LENGTH.constants'
import { imageJitURL } from '@src/utils/imageJitURL'

import {
  ExtraDetailsContainer,
  ImageContainer,
  ItemDetailsContainer,
  ItemDetailsHeader,
  Container,
  FlexRowContainer,
  MenuDescription,
  AllergenFavouriteContainer,
  MenuItemCounter,
  MenuItemCounterText,
  ItemDetailsHeaderAndCounter,
  SoldOutText,
  ChiliContainer,
} from './MenuItem.styles'
import { GetOutletMenuItemDocument } from './queries/__generated__/GetOutletMenuItem.graphql-interface'

export const MenuItem: React.FC<{
  outletId: string
  menuItemId: string
  finalElement: boolean
  dataTestId?: string
}> = ({ outletId, menuItemId, finalElement, dataTestId }) => {
  const [, setItemId] = useMenuItemQueryParam()
  const basketItems = useBasketItems()
  const { t } = useTranslation('menuItem')
  const menuItemCount = basketItems.items
    .filter(
      item =>
        item.menuItemId === menuItemId &&
        item.outletMenuItemId.includes(outletId)
    )
    .reduce((sum, item) => sum + item.quantity, 0)
  const { data, error } = useQuery(GetOutletMenuItemDocument, {
    variables: { outletId, menuItemId },
  })

  if (!data || error) {
    return null
  }

  const {
    name,
    price,
    allergens,
    image,
    description,
    soldOut,
    ageRestricted,
    spiceLevel,
  } = data.outletMenuItem

  const longDescription = truncate(description || undefined, {
    length: MAXIMUM_DESCRIPTION_LENGTH,
    separator: ' ',
    omission: ' ...',
  })

  return (
    <Container
      onClick={() => {
        setItemId(menuItemId, 'replaceIn')
      }}
      isDisabled={!!soldOut}
      finalElement={finalElement}
      data-test-id={dataTestId}
      title={name}
    >
      <FlexRowContainer isDisabled={!!soldOut}>
        <ItemDetailsContainer isDisabled={!!soldOut}>
          <div>
            <ItemDetailsHeaderAndCounter>
              <ItemDetailsHeader>{name}</ItemDetailsHeader>
              {spiceLevel > 0 && (
                <ChiliContainer>
                  {Array.from({ length: spiceLevel }).map((_, index) => (
                    <SpicyChiliSVG width={15} height={15} key={index} />
                  ))}
                </ChiliContainer>
              )}
              {!image && menuItemCount !== 0 && (
                <MenuItemCounter>
                  <MenuItemCounterText>{menuItemCount}</MenuItemCounterText>
                </MenuItemCounter>
              )}
            </ItemDetailsHeaderAndCounter>
            {description && (
              <MenuDescription>{longDescription}</MenuDescription>
            )}
          </div>
          <ExtraDetailsContainer>
            {soldOut ? (
              <SoldOutText> {t('sold_out')}</SoldOutText>
            ) : (
              <ItemCounter
                price={price}
                /* Default count to 0 (i.e. reset item) when re-opening single item modal */
                count={0}
                isDisabled={!!soldOut}
                isMenuItem={true}
              />
            )}

            <AllergenFavouriteContainer isDisabled={!!soldOut}>
              {(allergens || !!ageRestricted) && (
                <>
                  <AllergenTags
                    ageRestricted={!!ageRestricted}
                    isMenuitem={true}
                    allergens={allergens || []}
                    iconView
                  />
                </>
              )}
            </AllergenFavouriteContainer>
          </ExtraDetailsContainer>
        </ItemDetailsContainer>

        {image && (
          <ImageContainer
            imageUrl={imageJitURL(image, {
              resize: {
                width: 128,
                height: 128,
                fit: 'cover',
              },
              toFormat: 'jpeg',
            })}
          >
            {menuItemCount !== 0 && (
              <MenuItemCounter>
                <MenuItemCounterText>{menuItemCount}</MenuItemCounterText>
              </MenuItemCounter>
            )}
          </ImageContainer>
        )}
      </FlexRowContainer>
    </Container>
  )
}
