import styled, { css } from 'styled-components'

import { breakpoints } from '@src/constants/breakpoints'

export const Container = styled.div<{
  isDisabled: boolean
  finalElement: boolean
}>`
  all: unset;
  display: flex;
  flex-direction: column;
  border-bottom: ${({ finalElement }) =>
    !finalElement && '1px solid rgba(150, 150, 150, 0.2)'};
  border-radius: 1px;
  margin: 0;

  cursor: ${props => (props.isDisabled ? 'default' : 'pointer')};

  @media (min-width: ${breakpoints.tablet}px) {
    break-inside: avoid;
    align-content: space-between;
    justify-content: space-between;
    height: 100%;
    box-sizing: border-box;
    margin: 0px;
    border-bottom-width: 0px;
  }

  opacity: ${({ isDisabled }) => (isDisabled ? 0.5 : 1)};

  &:hover {
    ${({ isDisabled }) =>
      !isDisabled &&
      css`
        h4 {
          color: ${({ theme }) => theme.colors.brand};
        }
        span {
          color: ${({ theme }) => theme.colors.brandHighlight};
        }
        svg {
          color: ${({ theme }) => theme.colors.brandHighlight};
        }
      `}
  }
`

export const FlexRowContainer = styled.div<{ isDisabled: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 8px;
  margin-bottom: 8px;
  height: 100%;

  @media (min-width: ${breakpoints.tablet}px) {
    margin-top: 12px;
    margin-bottom: 12px;
  }
`

export const ExtraDetailsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
`

export const ImageContainer = styled.div<{ imageUrl?: string }>`
  width: 80px;
  height: 80px;
  min-width: 80px;
  min-height: 80px;
  margin-right: 8px;
  border-radius: 4px;
  ${props =>
    props.imageUrl &&
    css`
      background-image: url(${props.imageUrl});
      background-size: cover;
    `};

  background-size: cover;
  background-position: 50%;

  @media (min-width: ${breakpoints.tablet}px) {
    height: 128px;
    min-width: 128px;
    margin-right: 12px;
  }
`

export const ItemDetailsContainer = styled.div<{
  isDisabled: boolean
}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  margin-right: 12px;
  min-height: 42px;
`

export const ItemDetailsHeader = styled.h4`
  margin: 0;
  font-size: 14px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.darkText};
  padding-right: 8px;
  line-height: 1.2;
  flex: 1;
  @media (min-width: ${breakpoints.desktop}px) {
    font-size: 16px;
  }
`

export const ItemDetailsHeaderAndCounter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  align-items: flex-start;

  gap: 8px;

  @media (min-width: ${breakpoints.desktop}px) {
    min-height: 24px;
  }
`
export const MenuItemCounter = styled.div<{
  isOutletCard?: boolean
  isMobile?: boolean
}>`
  display: flex;
  width: 18px;
  min-width: 18px;
  height: 18px;
  border-radius: 20px;
  position: ${({ isMobile, isOutletCard }) =>
    (isOutletCard || isMobile) && 'absolute'};
  border: 2px solid rgba(255, 255, 255);
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.brand};
  position: relative;
  z-index: 1;
  left: -4px;
  top: -4px;

  ${({ isOutletCard }) => isOutletCard && `left: -8px; top: -8px;`}

  @media (min-width: ${breakpoints.tablet}px) {
    width: 20px;
    min-width: 20px;
    height: 20px;
    left: -6px;
    top: -6px;
  }

  @media (min-width: ${breakpoints.desktop}px) {
    width: 24px;
    min-width: 24px;
    height: 24px;
    left: -8px;
    top: -8px;
  }
`

export const MenuItemCounterText = styled.p`
  color: white;
  font-weight: 700;
  font-size: 11px;

  @media (min-width: ${breakpoints.tablet}px) {
    font-size: 12px;
  }
  @media (min-width: ${breakpoints.desktop}px) {
    font-size: 14px;
  }
`

export const SoldOutText = styled.p`
  color: ${({ theme }) => theme.colors.danger};
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 4px;
`

export const MenuDescription = styled.p`
  font-size: 12px;
  margin-top: 4px;
  font-weight: 400;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.darkText};
  white-space: pre-wrap;
  margin-bottom: 8px;

  @media (min-width: ${breakpoints.desktop}px) {
    padding-right: 0px;
    font-size: 13px;
    line-height: 17px;
  }
`

export const AllergenFavouriteContainer = styled.div<{ isDisabled: boolean }>`
  display: flex;
  align-items: center;

  @media (min-width: ${breakpoints.tablet}px) {
    text-align: inherit;
  }
`
export const ChiliContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 4px;
  height: 20px;

  @media (min-width: ${breakpoints.desktop}px) {
    height: 24px;
  }
`
