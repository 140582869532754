import { format } from 'date-fns'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { imageJitURL } from '@src/utils/imageJitURL'

import { OrderProgress } from './OrderProgress'
import {
  RestaurantLogo,
  StatusBoxContainer,
  StatusBoxPlaced,
  StatusBoxText,
  StatusBoxTextHeader,
  StatusBoxUpdate,
  StatusInfoContainer,
  StatusPhotoText,
} from './StatusBox.styles'

export const PendingStatusBox: React.VFC<{
  updatedAt: string
  restaurantName: string
  restaurantLogo: string | null
  isAccountPage: boolean
}> = ({ updatedAt, restaurantName, restaurantLogo, isAccountPage }) => {
  const { t } = useTranslation('singleOrder')
  const orderUpdatedAt = formatDistanceToNow(new Date(updatedAt), {
    addSuffix: true,
  })

  return (
    <StatusBoxContainer isAccountPage={isAccountPage}>
      <StatusBoxPlaced>
        {format(new Date(updatedAt), 'dd MMM yyyy')}
      </StatusBoxPlaced>
      <StatusBoxUpdate>
        {t('updated')} {orderUpdatedAt}
      </StatusBoxUpdate>
      <StatusPhotoText>
        <RestaurantLogo
          role="img"
          aria-label={`${restaurantName}`}
          imageUrl={imageJitURL(restaurantLogo, {
            resize: {
              width: 128,
              height: 128,
              fit: 'cover',
            },
            toFormat: 'jpeg',
          })}
        />
        <StatusInfoContainer>
          <StatusBoxTextHeader>{restaurantName}</StatusBoxTextHeader>
          <StatusBoxText>
            {t('awaiting_order_acceptance', { restaurantName })}
          </StatusBoxText>
        </StatusInfoContainer>
      </StatusPhotoText>
      <OrderProgress valueNow={1} />
    </StatusBoxContainer>
  )
}
