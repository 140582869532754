import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Swiper as SwiperType } from 'swiper/types'

import {
  OutletFulfilmentStateType,
  useOutletFulfilment,
} from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/useOutletFulfilment'
import {
  CheckoutRoute,
  useCheckoutRouter,
} from '@src/hooks/useCheckoutRouter/useCheckoutRouter'
import {
  useBasketTotals,
  UseBasketTotalsResponseData,
} from '@src/hooks/useTotals/useBasketTotals'

import { LoyaltyCard } from './CustomerLoyaltyCard'
import { LoyaltyCardSliderContainer } from './CustomerLoyaltyCard.styles'
import { amountToSpendToEarnAStamp } from './utils/calculate-amount-to-spend-to-earn-a-stamp'

import {
  ArrowContainer,
  HeaderContainer,
} from '../ActiveOrdersSlider/ActiveOrdersSlider.styles'
import { GroupHeader } from '../BurgerMenu/AccountMain/AccountNavigationLinks.styles'
import { IconButton } from '../Button/IconButton'
import { Carousel } from '../Carousel'
import { outletFulfilment_outlet_outletLoyaltyCards } from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilmentData/queries/__generated__/outletFulfilment'

const filterLoyaltyCards = (
  cards: outletFulfilment_outlet_outletLoyaltyCards[],
  showFullCards: boolean,
  basketTotals?: UseBasketTotalsResponseData | null
) =>
  cards.filter(card => {
    const canEarnStamp = card.canStampBeEarnedToday
    const isCardIncomplete =
      !showFullCards &&
      (card.loyaltyCardStamps?.length ?? 0) < card.requiredStamps

    const meetsBasketCriteria =
      !basketTotals ||
      amountToSpendToEarnAStamp({
        discount: card.discount,
        basketTotals,
        discountStrategy: card.discountStrategy,
      }) === 0

    return (
      canEarnStamp && (isCardIncomplete || showFullCards) && meetsBasketCriteria
    )
  })

export const CheckoutLoyaltyCardSlider: React.FC = () => {
  const { t } = useTranslation('customerLoyaltyCard')
  const [currentIndex, setCurrentIndex] = useState(0)
  const [loyaltyCardSwiperInstance, setLoyaltyCardSwiperInstance] =
    useState<SwiperType | null>(null)

  const { outlet } = useOutletFulfilment({
    stateType: OutletFulfilmentStateType.GLOBAL,
  })

  const { data: basketTotals } = useBasketTotals()
  const showFullCards =
    !!basketTotals?.checkoutBasketTotals?.appliedDiscount?.discount
      .loyaltyCard ||
    !basketTotals?.checkoutBasketTotals?.appliedDiscount?.discount
  const checkout = useCheckoutRouter()

  const cardsData = basketTotals?.checkoutBasketTotals?.loyaltyCards?.length
    ? basketTotals?.checkoutBasketTotals?.loyaltyCards
    : outlet.outletLoyaltyCards

  if (!cardsData.length) {
    return null
  }

  // filter out the ones that don't reach the minimum required total to earn a stamp, except if they are full
  // and full ones if the flag is false

  const loyaltyCards =
    checkout.route === CheckoutRoute.BASKET || checkout.route === undefined
      ? filterLoyaltyCards(cardsData, showFullCards)
      : filterLoyaltyCards(cardsData, showFullCards, basketTotals ?? undefined)

  const scrollToNextCard = () => {
    const indexToScrollTo = currentIndex + 1
    setCurrentIndex(indexToScrollTo)
    loyaltyCardSwiperInstance?.slideTo(indexToScrollTo, 500)
  }

  const scrollToPreviousCard = () => {
    const indexToScrollTo = currentIndex - 1
    setCurrentIndex(indexToScrollTo)
    loyaltyCardSwiperInstance?.slideTo(indexToScrollTo, 500)
  }

  return (
    <>
      {loyaltyCards.length > 0 && (
        <HeaderContainer>
          <GroupHeader>{t('my_loyalty_cards')}</GroupHeader>
          {loyaltyCards.length > 1 && (
            <ArrowContainer>
              <IconButton
                disabled={currentIndex === 0}
                onClick={e => {
                  e.preventDefault()
                  if (currentIndex > 0) {
                    scrollToPreviousCard()
                  }
                }}
                size={32}
                icon={'caretLeft'}
                alt={t('left')}
                role={'presentation'}
              />
              <IconButton
                disabled={currentIndex === loyaltyCards.length - 1}
                onClick={e => {
                  e.preventDefault()
                  if (currentIndex !== loyaltyCards.length - 1) {
                    scrollToNextCard()
                  }
                }}
                size={32}
                icon={'caretRight'}
                alt={t('right')}
                role={'presentation'}
              />
            </ArrowContainer>
          )}
        </HeaderContainer>
      )}
      <LoyaltyCardSliderContainer singleBasketCard={loyaltyCards.length === 1}>
        <Carousel
          allowTouchMove={false}
          setSwiperInstance={setLoyaltyCardSwiperInstance}
          slides={loyaltyCards.map(loyaltyCard => {
            return (
              <LoyaltyCard
                loyaltyCard={loyaltyCard}
                key={`${loyaltyCard.id}`}
                basketTotals={basketTotals ?? undefined}
                singleBasketCard={loyaltyCards.length === 1}
              />
            )
          })}
        />
      </LoyaltyCardSliderContainer>
    </>
  )
}
