import { capitalize } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ButtonType } from '@src/components/Button'
import { AlertModal } from '@src/components/Modal/AlertModal'
import { NarrowFulfilmentMethodInputType } from '@src/graphql-types'
import { useMenuItemQueryParam } from '@src/hooks/useMenuItemQueryParam'

export const MenuItemNotAvailableModal: React.FC<{
  isOpen: boolean
  setIsOpen: (state: boolean) => void
  fulfilmentMethodName: NarrowFulfilmentMethodInputType
  setFulfilmentMethod: (fulfilment: NarrowFulfilmentMethodInputType) => void
  availableFulfilments: NarrowFulfilmentMethodInputType[]
  submitForm: () => void
}> = ({
  isOpen,
  setIsOpen,
  fulfilmentMethodName,
  availableFulfilments,
  setFulfilmentMethod,
  submitForm,
}) => {
  const [, setItemId] = useMenuItemQueryParam()
  const { t } = useTranslation('menuItem')

  return (
    <>
      <AlertModal
        isOpen={isOpen}
        title={
          availableFulfilments.length
            ? t(`not_available_for`, {
                fulfilmentMethodName: t(fulfilmentMethodName.toLowerCase()),
              })
            : t('item_not_available')
        }
        action={
          availableFulfilments.length
            ? {
                text: capitalize(
                  t(`change_fulfilment_to`, {
                    fulfilmentMethod: t(
                      availableFulfilments[0] as NarrowFulfilmentMethodInputType
                    ).toLowerCase(),
                  })
                ),
                intent: ButtonType.PRIMARY,
                onClick: () => {
                  if (availableFulfilments?.length) {
                    setFulfilmentMethod(
                      availableFulfilments[0] as NarrowFulfilmentMethodInputType
                    )
                  }
                  // wait for fulfilment state to change
                  submitForm()
                  setIsOpen(false)
                  setItemId(undefined)
                },
              }
            : {
                text: t('ok'),
                intent: ButtonType.PRIMARY,
                onClick: () => {
                  setItemId(undefined)
                  setIsOpen(false)
                },
              }
        }
        cancel={
          availableFulfilments.length
            ? {
                text: t(`cancel`),
                intent: ButtonType.SECONDARY,
                onClick: () => {
                  setIsOpen(false)
                },
              }
            : undefined
        }
      />
    </>
  )
}
